import moment from 'moment';
import { put, takeLatest } from 'redux-saga/effects';
import { createAppTableSaga, createAppTableStore, createPersist } from 'components/AppTable/store';
import apiRoutes from 'config/apiRoute';
import { convertMinsToHrsMins, convertToHHMM } from 'utils/string.utils';

const startOfWeek = moment().startOf('week').format('YYYY-MM-DD');
const endOfWeek = moment().endOf('week').format('YYYY-MM-DD');

export const timelogReportSlice = createAppTableStore({
  name: 'timelogReport' as const,
  url: apiRoutes.TIME_LOG_REPORT,
  initialState: {
    params: {
      filters: {
        date_start: { value: startOfWeek },
        date_end: { value: endOfWeek },
      },
    },
    fixedParams: { 'filters[status][]': 'completed' },
    paramFilterFields: {
      project: 'filters[project_uuid][]',
      user: 'filters[user_uuid][]',
      date_start: 'date_from',
      date_end: 'date_to',
    },
    totalTime: null as null | string,
  },
  reducers: {
    setTotalTime: (state, { payload }: { payload: string; type: string }) => {
      return {
        ...state,
        totalTime: payload,
      };
    },
  },
});

function* handleApiResponseSagaListener({ payload }: { payload: any }) {
  yield put(
    timelogReportSlice.actions.setTotalTime(
      convertToHHMM(convertMinsToHrsMins(Number(payload.tasks_total_time ?? 0) + Number(payload.requests_total_time ?? 0))),
    ),
  );
}

export const timelogReportSaga = createAppTableSaga(timelogReportSlice, function* customGenerator() {
  yield takeLatest(timelogReportSlice.actions.handleApiResponse, handleApiResponseSagaListener);
});

export const timelogReportReducer = createPersist(timelogReportSlice, []);
