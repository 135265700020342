import { FC, useRef, useState, JSX, useEffect } from 'react';
import { Button, Popconfirm, Row, Space, Tag, Comment, Typography } from 'antd';
import { DeleteOutlined, FileImageOutlined } from '@ant-design/icons';
import RichTextEditor, { RichTextEditorHandle } from 'components/RichTextEditor';
import UserAvatar from 'components/UserAvatar';
import DownloadAsset from 'components/UploadsList/components/DownloadAsset';
import { convertSnakeCaseToTitleCase, mentionCommentParser, parseDateToUSFormat } from 'utils/string.utils';
import appRoutes from 'config/appRoutes';
import { UserResponse } from 'types';
import { UploadFileApi, UploadResponse } from 'types/upload-type';
import CommentActions, { CommentActionsParentProps, CommentActionsChildrenProps } from './CommentActions';

const { Text } = Typography;

interface CommentCardBaseProps {
  comment: any;
  user: UserResponse;
  projectUuid: string;
  targetCommentId: any;
  type: 'tasks' | 'projects' | 'requests';
  scrollingContainer?: string | HTMLElement | undefined;
  onlyMentionRegularUser?: boolean;
  isProjectPersonal?: boolean;
  onImgDelete: any;
  onReplyTo: CommentActionsParentProps['onReplyTo'];
  onImageModalChanged?: (open: boolean) => void;
}

interface CommentCardParentProps extends CommentCardBaseProps {
  parentEl?: undefined;
  onAddReaction: CommentActionsParentProps['onAddReaction'];
  onRemoveReaction: CommentActionsParentProps['onRemoveReaction'];
  onUpdate: CommentActionsParentProps['onUpdate'];
  onDelete: CommentActionsParentProps['onDelete'];
}

interface CommentCardChildrenProps extends CommentCardBaseProps {
  parentEl: CommentActionsChildrenProps['parentEl'];
  onAddReaction: CommentActionsChildrenProps['onAddReaction'];
  onRemoveReaction: CommentActionsChildrenProps['onRemoveReaction'];
  onUpdate: CommentActionsChildrenProps['onUpdate'];
  onDelete: CommentActionsChildrenProps['onDelete'];
}

export type CommentCardProps = CommentCardParentProps | CommentCardChildrenProps;

const CommentCard: FC<CommentCardProps> = (props) => {
  const {
    comment,
    user,
    projectUuid,
    targetCommentId,
    children,
    scrollingContainer,
    onlyMentionRegularUser,
    type,
    isProjectPersonal,
    onImgDelete,
    onReplyTo,
    onImageModalChanged,
  } = props;

  const [editing, setEditing] = useState(false);
  const [images, setImages] = useState<UploadFileApi[]>([]);
  const [loadingImages, setLoadingImages] = useState(0);

  const commentRef = useRef<RichTextEditorHandle>(null);
  const wrapEditing = editing && !comment.deleted_at;

  const bgTargetComment = targetCommentId === comment.uuid ? '#e2e2e2' : undefined;
  const bgPrivate = comment.type === 'private' ? 'yellow' : undefined;

  let actions: JSX.Element | undefined;

  if (!comment.deleted_at) {
    actions = props.parentEl ? (
      <CommentActions
        key="actions"
        comment={comment}
        user={user}
        images={images}
        loadingImages={loadingImages}
        editing={wrapEditing}
        editorText={commentRef}
        parentEl={props.parentEl}
        type={type}
        onEdit={setEditing}
        onReplyTo={onReplyTo}
        onAddReaction={props.onAddReaction}
        onRemoveReaction={props.onRemoveReaction}
        onUpdate={props.onUpdate}
        onDelete={props.onDelete}
      />
    ) : (
      <CommentActions
        key="actions"
        comment={comment}
        user={user}
        images={images}
        loadingImages={loadingImages}
        editing={wrapEditing}
        editorText={commentRef}
        type={type}
        onEdit={setEditing}
        onReplyTo={onReplyTo}
        onAddReaction={props.onAddReaction}
        onRemoveReaction={props.onRemoveReaction}
        onUpdate={props.onUpdate}
        onDelete={props.onDelete}
      />
    );
  }

  useEffect(() => {
    const parserText = mentionCommentParser(comment.text);
    if (commentRef.current?.getValue() !== parserText) {
      commentRef.current?.updateValue(parserText);
    }
  }, [comment.text]);

  useEffect(() => {
    if (!editing) {
      setImages([]);
      setLoadingImages(0);
    }
  }, [editing]);

  return (
    <div
      className={`comment-${comment.uuid}`}
      style={{
        borderRadius: 5,
        background: bgTargetComment ?? bgPrivate,
        padding: '0 10px',
      }}
    >
      <Comment
        author={
          <>
            <Space style={{ minHeight: 22 }}>
              <Text>{comment.creator?.name}</Text>
              <Text className="disabled">{parseDateToUSFormat(comment.created_at, true)}</Text>
              {!props.parentEl && type === 'projects' && (
                <Tag color={comment.type === 'private' ? 'gold' : 'blue'}>{convertSnakeCaseToTitleCase(comment.type)}</Tag>
              )}
              {comment.created_at !== comment.updated_at && !comment.deleted_at && <Text className="disabled">[Edited]</Text>}
            </Space>

            {comment.creator?.job_title ? (
              <div style={{ lineHeight: 1, marginBottom: 4 }}>{comment.creator?.job_title}</div>
            ) : undefined}
          </>
        }
        avatar={<UserAvatar user={comment.creator} />}
        content={
          <div>
            <RichTextEditor
              ref={commentRef}
              className={`mention-compact${wrapEditing ? '' : ' comment-styles'}${comment.deleted_at ? ' deleted-message' : ''}`}
              placeholder="Add a comment"
              initialValue={mentionCommentParser(comment.text)}
              projectId={projectUuid}
              scrollingContainer={scrollingContainer}
              includeToolbar={false}
              includeMention={!isProjectPersonal}
              mentionTypes={onlyMentionRegularUser ? ['regular_user', 'freelancer', 'group'] : undefined}
              readonly={!wrapEditing}
              noContainer={!wrapEditing}
              style={{ backgroundColor: wrapEditing ? 'white' : undefined }}
              onImageModalChanged={onImageModalChanged}
              onLoadingImage={(value) => {
                if (editing) setLoadingImages(value);
              }}
              onImageUploader={async (upload) => {
                setImages((prev) => [...prev, upload]);
              }}
            />

            {comment.uploads.length > 0 && (
              <Space size={10} direction="vertical" style={{ width: '100%', marginTop: 12 }}>
                {comment.uploads.map((upload: UploadResponse) => {
                  return (
                    <Row
                      style={{
                        background: '#F0F0F0',
                        padding: 5,
                        borderRadius: 3,
                        paddingLeft: 20,
                      }}
                      align="middle"
                      justify="space-between"
                      key={upload.uuid}
                    >
                      <Typography.Link target="_blank" href={`${appRoutes.FILE_VIEWER}/${upload.uuid}`}>
                        <FileImageOutlined className="mr-m" />

                        <span>{upload.name}</span>
                      </Typography.Link>

                      <Space>
                        <DownloadAsset uuid={upload.uuid} name={upload.name} />

                        {!comment.deleted_at && comment.creator.uuid === user.uuid && (
                          <Popconfirm
                            placement="bottom"
                            title="Do you want to delete this image?"
                            onConfirm={() => onImgDelete(upload.uuid, comment.uuid)}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button type="text" icon={<DeleteOutlined />} />
                          </Popconfirm>
                        )}
                      </Space>
                    </Row>
                  );
                })}
              </Space>
            )}
            {actions}
          </div>
        }
        key={comment.uuid}
      >
        {children}
      </Comment>
    </div>
  );
};

export default CommentCard;
